import { FC } from "react"

import { Minus } from "../icons/Minus"
import { Plus } from "../icons/Plus"

import {
  accordion,
  accordionContent,
  accordionHeadline,
  accordionHeadlineIcon,
  accordionHeadlineTitle,
} from "./styles.css"
import { Body } from "@casavo/habitat"

type AccordionProps = {
  children: React.ReactNode
  className?: string
  isOpen: boolean
  onToggle: () => void
  title: string
}

export const Accordion: FC<AccordionProps> = ({ children, className, isOpen, onToggle, title }) => {
  return (
    <div className={`${accordion} ${className}`}>
      <div className={accordionHeadline} onClick={onToggle}>
        <div className={accordionHeadlineTitle}>
          <Body noMargin>{title}</Body>
        </div>
        {isOpen ? <Minus className={accordionHeadlineIcon} /> : <Plus className={accordionHeadlineIcon} />}
      </div>
      <div className={`${accordionContent} ${isOpen ? "open" : ""}`}>{children}</div>
    </div>
  )
}
